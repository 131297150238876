import { DeploymentStatus } from '../__generated__/globalTypes';

export default function errorExplanation(
  errorInternal: DeploymentStatus,
): string {
  switch (errorInternal) {
    default:
      return '';
    case DeploymentStatus.Deleted:
      return 'This Deployment has been deleted, and will be fully removed within a few days.';
    case DeploymentStatus.Error:
      return 'An internal error occurred with this deployment. See Error Details for more information.';
    case DeploymentStatus.PendingDelete:
      return "This Deployment is scheduled for deletion the next time it's reconciled.";
    case DeploymentStatus.PendingUpdate:
      return 'This Deployment has been modified and will be reconciled shortly.';
    case DeploymentStatus.AwaitingBuild:
      return 'This Deployment is missing one or more builds. Once a CI build has run on each of its branches, Amphora will automatically deploy the stack.';
    case DeploymentStatus.Synced:
      return 'This Deployment is up-to-date.';
    case DeploymentStatus.Reconciling:
      return 'This Deployment is being reconciled. This process typically takes less than a minute.';
    case DeploymentStatus.InfraDeleted:
      return 'Kubernetes infrastructure has been torn down. The database will be deleted shortly.';
    case DeploymentStatus.PendingDBDelete:
      return 'The database is scheduled for deletion the next time this Deployment is reconciled.';
    case DeploymentStatus.TearingDown:
      return 'This Deployment is being torn down. This process typically takes less than a minute.';
  }
}
