import { styled } from '@onehope/design-system-v2';

import ohlogo from './OH-Icon-Black.png';

const Container = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const OhLogoImage = styled('img')`
  height: 25px;
  width: auto;
  margin-top: 15px;

  @media (prefers-color-scheme: dark) {
    filter: invert(1);
  }
`;

export default function AmphoraLogo() {
  return (
    <Container>
      <h3 className="playfair no-bottom-margin">
        <a href="https://amphora.onehopewine-staging.com/">🏺 Amphora</a>
      </h3>
      <OhLogoImage src={ohlogo} />
    </Container>
  );
}
