import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import {
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  makeStyles,
  TextField,
  Typography,
} from '@onehope/design-system-v2';

import { DeploymentStatus } from '../__generated__/globalTypes';
import StatusText from '../DeploymentCardView/StatusText';
import { DELETE_DEPLOYMENT_MUTATION } from '../queries/deleteDeploymentMutation';
import {
  DeleteDeployment,
  DeleteDeploymentVariables,
} from '../queries/generatedTypes/DeleteDeployment';
import { GetDeploymentDetails_deployment } from '../queries/generatedTypes/GetDeploymentDetails';
import {
  UpsertDeployment,
  UpsertDeploymentVariables,
} from '../queries/generatedTypes/UpsertDeployment';
import { UPSERT_DEPLOYMENT_MUTATION } from '../queries/upsertDeploymentMutation';
import StatusCard from '../StatusCard/StatusCard';

//#region Styles

const useStyles = makeStyles({
  descriptionInput: {
    width: '100%',
  },
  branchInput: {
    minWidth: '50%',
    // display: 'block',
    marginBottom: '2em',
  },
  branchConfig: {
    marginTop: '1em',
  },
});

//#region Deployment Form Component

interface DeploymentFormProps {
  deployment: GetDeploymentDetails_deployment;
}

export default function DeploymentForm({ deployment }: DeploymentFormProps) {
  const history = useHistory();
  const classes = useStyles();

  const [formError, setFormError] = useState<null | string>(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const [description, setDescription] = useState('');
  const [ttlDays, setTtlDays] = useState<string | undefined>(undefined);
  const [branchMicroservice, setBranchMicroservice] = useState('');
  const [branchHopecommerce, setBranchHopecommerce] = useState('');
  const [branchMonorepo, setBranchMonorepo] = useState('');
  const [branchInfrastructure, setBranchInfrastructure] = useState('');

  const [updateDeployment] = useMutation<
    UpsertDeployment,
    UpsertDeploymentVariables
  >(UPSERT_DEPLOYMENT_MUTATION);
  const [deleteDeployment] = useMutation<
    DeleteDeployment,
    DeleteDeploymentVariables
  >(DELETE_DEPLOYMENT_MUTATION);

  // copy deployment data into state "draft"
  useEffect(() => {
    setDescription(deployment.description || '');
    setBranchMicroservice(deployment.branchMicroservice || '');
    setBranchHopecommerce(deployment.branchHopecommerce || '');
    setBranchMonorepo(deployment.branchMonorepo || '');
    setBranchInfrastructure(deployment.branchInfrastructure || '');

    // lazily convert expiration date to a number of days remaining
    if (deployment.expiresAt) {
      const daysRemaining = DateTime.fromISO(deployment.expiresAt).diffNow(
        'days',
      ).days;
      setTtlDays(Math.max(1, Math.ceil(daysRemaining)).toString());
    } else {
      // if this is the first time we're editing this deployment, set the TTL to a default of 7 days
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get('new') === 'true') {
        setTtlDays('7');
      }
    }
  }, []); // Runs only once because of empty dependency array

  const handleSave = useCallback(async () => {
    try {
      const parsedTtlDays =
        typeof ttlDays !== 'undefined' ? +ttlDays : undefined;

      await updateDeployment({
        variables: {
          input: {
            slug: deployment.slug,
            description,
            ttlDays: parsedTtlDays?.toString() ?? null,
            branchMicroservice,
            branchHopecommerce,
            branchMonorepo,
            branchInfrastructure,
          },
        },
      });
      history.push(`/deployment/${deployment.slug}`);
    } catch (error) {
      console.error(error);
      setFormError(error.message);
    }
  }, [
    history,
    updateDeployment,
    deployment.slug,
    description,
    branchMicroservice,
    branchHopecommerce,
    branchMonorepo,
    branchInfrastructure,
    ttlDays,
  ]);

  const confirmDelete = useCallback(() => {
    setShowConfirmDelete(true);
  }, [setShowConfirmDelete]);

  const cancelDelete = useCallback(() => {
    setShowConfirmDelete(false);
  }, [setShowConfirmDelete]);

  const handleDelete = useCallback(async () => {
    try {
      cancelDelete();
      await deleteDeployment({
        variables: {
          slug: deployment.slug,
        },
      });

      history.push(`/`);
    } catch (error) {
      console.error(error);
      setFormError(error);
    }
  }, [deleteDeployment, setFormError, deployment.slug, history, cancelDelete]);

  const onChange = useCallback((field: string) => {
    let fn: CallableFunction;

    switch (field) {
      default:
        throw new Error(`Unrecognized field: ${field}`);
      case 'description':
        fn = setDescription;
        break;
      case 'ttlDays':
        fn = setTtlDays;
        break;
      case 'microservice':
        fn = setBranchMicroservice;
        break;
      case 'hopecommerce':
        fn = setBranchHopecommerce;
        break;
      case 'monorepo':
        fn = setBranchMonorepo;
        break;
      case 'infrastructure':
        fn = setBranchInfrastructure;
        break;
    }

    return (e: any) => {
      fn(e.target.value);
    };
  }, []);

  //#region HTML

  return (
    <form>
      <Dialog open={showConfirmDelete} onClose={cancelDelete}>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this Deployment, including its
            database?
          </DialogContentText>
          <DialogActions>
            <Button onClick={cancelDelete} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDelete} color="primary">
              Delete It!
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Button variant="outlined" color="secondary" onClick={confirmDelete}>
        Delete Deployment
      </Button>
      <br />
      <br />
      <br />

      <TextField
        id="description"
        className={classes.descriptionInput}
        label="Description"
        value={description}
        onChange={onChange('description')}
        fullWidth
      />

      <br />
      <br />

      <div className={classes.branchConfig}>
        <h2>Auto-Delete</h2>
        <TextField
          id="ttlDays"
          className={classes.branchInput}
          label="TTL (Days)"
          value={ttlDays ?? ''}
          onChange={onChange('ttlDays')}
        />
      </div>

      <div className={classes.branchConfig}>
        <h2>Branch Configuration</h2>
        <br />

        <TextField
          id="branchMicroservice"
          className={classes.branchInput}
          variant="outlined"
          label="microservice"
          value={branchMicroservice}
          onChange={onChange('microservice')}
        />

        <br />
        <TextField
          id="branchHopecommerce"
          className={classes.branchInput}
          variant="outlined"
          label="hopecommerce"
          value={branchHopecommerce}
          onChange={onChange('hopecommerce')}
        />

        <br />
        <TextField
          id="branchMonorepo"
          className={classes.branchInput}
          variant="outlined"
          label="monorepo"
          value={branchMonorepo}
          onChange={onChange('monorepo')}
        />

        <br />
        <TextField
          id="branchInfrastructure"
          className={classes.branchInput}
          variant="outlined"
          label="onehope-infrastructure"
          value={branchInfrastructure}
          onChange={onChange('infrastructure')}
        />

        <br />
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSave()}
        >
          Save
        </Button>
      </div>

      {formError && (
        <>
          <br />
          <br />
          <StatusCard statusText={DeploymentStatus.Error}>
            <>
              <CardContent>
                <StatusText status={DeploymentStatus.Error} />
                <Typography variant="body1" component="p">
                  {formError}
                </Typography>
              </CardContent>
            </>
          </StatusCard>
        </>
      )}
    </form>
  );
}
