import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { ApolloProvider } from '@apollo/client';

import AmphoraLogo from './AmphoraLogo/AmphoraLogo';
import getClient from './apollo-client';
import CardView from './DeploymentCardView/CardView';
import DeploymentDetail from './DeploymentDetail/DeploymentDetail';
import EditDeployment from './EditDeployment/EditDeployment';

interface Props {
  sessionKey: string | null;
}

export default function AmphoraMain({ sessionKey }: Props) {
  const apolloClient = getClient(sessionKey);

  return (
    <ApolloProvider client={apolloClient}>
      <Router>
        <AmphoraLogo />
        <Switch>
          <Route path="/deployment/:slug/edit">
            <EditDeployment />
          </Route>
          <Route path="/deployment/:slug">
            <DeploymentDetail />
          </Route>
          <Route path="/">
            <CardView />
          </Route>
        </Switch>
      </Router>
    </ApolloProvider>
  );
}
