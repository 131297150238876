type Shipment = {
  shipmentInformation?: {
    trackingLink?: string;
  };
};

export const getTrackingLinks = (shipments: Shipment[] | null) => {
  if (!shipments?.length) {
    return [];
  }
  return shipments
    .map((shipment) => shipment?.shipmentInformation?.trackingLink)
    .filter((link) => link);
};
