import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import {
  CircularProgress,
  Container,
  Text,
  Typography,
} from '@onehope/design-system-v2';

import AmphoraLogo from './AmphoraLogo/AmphoraLogo';
import AmphoraMain from './AmphoraMain';
import { GithubLogin } from './GithubLogin';

export default function App() {
  const [sessionKey, setSessionKey] = useState<string | null>(null);
  const [authError, setAuthError] = useState(false);

  useEffect(() => {
    async function getToken() {
      const amphoraCookie = Cookies.get('amphora_login');
      if (amphoraCookie && amphoraCookie !== '') {
        setSessionKey(amphoraCookie);
      } else {
        setAuthError(true);
      }
    }
    getToken();
  }, []); // Runs only once because of empty dependency array

  if (process.env.NODE_ENV === 'development') {
    return (
      <Container maxWidth="md">
        <AmphoraMain sessionKey={'devkey'} />
      </Container>
    );
  }

  if (authError) {
    return (
      <Container maxWidth="md">
        <Router>
          <AmphoraLogo />
          <br />
          <Text component="h3">Failed to authenticate!</Text>
          <br />
          <GithubLogin />
        </Router>
      </Container>
    );
  }

  if (sessionKey === null) {
    return (
      <Container maxWidth="md">
        <Router>
          <AmphoraLogo />
          <Typography variant="body1" component="p">
            <CircularProgress size="1em" /> Authenticating...
          </Typography>
        </Router>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <AmphoraMain sessionKey={sessionKey} />
    </Container>
  );
}
