import { Repo } from '../__generated__/globalTypes';

/**
 * Translate an enumerated Repo to the name of the CircleCI project for linking to builds
 * @param repo
 * @returns
 */
const getCircleRepoName = (repo: Repo | string): string => {
  switch (repo) {
    case Repo.Microservice:
      return 'microservice';
    case Repo.Hopecommerce:
      return 'hopecommerce';
    case Repo.Monorepo:
      return 'onehope';
    case Repo.Infrastructure:
      return 'onehope-infrastructure';
    default:
      return '';
  }
};

export default getCircleRepoName;
