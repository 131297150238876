import { muiColors, styled } from '@onehope/design-system-v2';

const BranchLabelSpan = styled('span')`
  background-color: ${muiColors.grey[200]};
  color: ${muiColors.grey[800]};
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;

  @media (prefers-color-scheme: dark) {
    background-color: #333;
    color: #f5f5f5;
  }
`;

interface Props {
  branchName?: string;
}

export default function BranchLabel({ branchName }: Props) {
  return <BranchLabelSpan>{branchName ?? 'unknown branch'}</BranchLabelSpan>;
}
