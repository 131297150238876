import { gql } from '@apollo/client';

const ALL_DEPLOYMENTS_QUERY = gql`
  query GetAllDeployments {
    deployments {
      id
      slug
      status
      locked
      description
      expiresAt
      branchMicroservice
      branchHopecommerce
      branchMonorepo
    }
  }
`;

export default ALL_DEPLOYMENTS_QUERY;
