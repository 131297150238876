import { gql } from '@apollo/client';

const DEPLOYMENT_DETAILS_QUERY = gql`
  query GetDeploymentDetails($slug: String!) {
    deployment(slug: $slug) {
      id
      slug
      status
      locked
      description
      reconciledAt
      expiresAt
      errorText
      branchMicroservice
      branchHopecommerce
      branchMonorepo
      branchInfrastructure
    }
    deploymentEvents(slug: $slug) {
      id
      repo
      eventType
      eventData {
        buildNumber
        commitId
        oldTag
        newTag
      }
    }
    deploymentBuildInfo(slug: $slug) {
      id
      repo
      healthReport {
        status
        updateDate
      }
    }
  }
`;

export default DEPLOYMENT_DETAILS_QUERY;
