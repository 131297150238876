import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
} from '@onehope/design-system-v2';

import errorHandling from '../helpers/errorHandling';
import ALL_DEPLOYMENTS_QUERY from '../queries/allDeploymentsQuery';
import { CREATE_DEPLOYMENT_MUTATION } from '../queries/createDeploymentMutation';
import {
  CreateDeployment,
  CreateDeploymentVariables,
} from '../queries/generatedTypes/CreateDeployment';
import { GetAllDeployments } from '../queries/generatedTypes/GetAllDeployments';
import DeploymentCard from './DeploymentCard';

const useStyles = makeStyles({
  slugInput: {
    display: 'flex',
    alignItems: 'end',
  },
});

export default function CardView(props: any) {
  const classes = useStyles();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [newSlug, setNewSlug] = useState('');

  const [createDeployment] = useMutation<
    CreateDeployment,
    CreateDeploymentVariables
  >(CREATE_DEPLOYMENT_MUTATION);
  const { loading, error, data } = useQuery<GetAllDeployments>(
    ALL_DEPLOYMENTS_QUERY,
    {
      pollInterval: 10000,
    },
  );

  const handleChangeSlug = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setNewSlug(e.target.value);
    },
    [setNewSlug],
  );

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleCreate = useCallback(async () => {
    await createDeployment({
      variables: {
        input: {
          slug: newSlug,
        },
      },
    });
    handleClose();
    history.push(`/deployment/${newSlug}/edit?new=true`);
  }, [newSlug, handleClose, createDeployment, history]);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        handleCreate();
      }
    },
    [handleCreate],
  );

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return errorHandling(error);
  }

  if (!data?.deployments) {
    console.warn('Missing data in GraphQL response');
    return null;
  }

  const { deployments } = data;
  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create Deployment</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose a slug for your new Deployment. It should be short, yet
            descriptive, and will determine the subdomain where the stack is
            hosted.
          </DialogContentText>

          <div className={classes.slugInput}>
            <TextField
              id="new-slug"
              autoFocus
              label="Deployment Slug"
              type="text"
              value={newSlug}
              onChange={handleChangeSlug}
              onKeyUp={handleKeyPress}
            />
            .amphora.onehopewine-staging.com
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCreate} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>

      <h1>Deployments</h1>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        New Deployment
      </Button>
      <br />
      <br />

      <Grid container spacing={3}>
        {deployments.map((deployment) => (
          <Grid item key={deployment.slug}>
            <DeploymentCard deployment={deployment} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
