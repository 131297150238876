import { useEffect, useState } from 'react';

import { muiColors, styled, Text } from '@onehope/design-system-v2';

import { DeploymentStatus } from '../__generated__/globalTypes';

const getClassByStatus = (statusText: string) => {
  switch (statusText) {
    default:
      return '';
    case DeploymentStatus.Deleted:
    case DeploymentStatus.PendingDBDelete:
    case DeploymentStatus.PendingDelete:
    case DeploymentStatus.Reconciling:
    case DeploymentStatus.TearingDown:
      return 'deleted';

    case DeploymentStatus.Error:
      return 'error';

    case DeploymentStatus.AwaitingBuild:
    case DeploymentStatus.PendingUpdate:
    case DeploymentStatus.InfraDeleted:
      return 'pending';

    case DeploymentStatus.Synced:
      return 'synced';
  }
};

const StyledText = styled(Text)`
  text-transform: uppercase;
  font-family: monospace;
  font-size: 0.8rem;
  font-weight: 900;

  &.error {
    color: ${muiColors.red[700]};
    @media (prefers-color-scheme: dark) {
      color: ${muiColors.red[300]};
    }
  }
  &.pending {
    color: ${muiColors.deepPurple[400]};
    @media (prefers-color-scheme: dark) {
      color: ${muiColors.deepPurple[300]};
    }
  }
  &.synced {
    color: ${muiColors.green[700]};
    @media (prefers-color-scheme: dark) {
      color: ${muiColors.green[300]};
    }
  }
  &.deleted {
    color: ${muiColors.grey[600]};
    @media (prefers-color-scheme: dark) {
      color: ${muiColors.grey[300]};
    }
  }
`;

interface Props {
  innerText?: string;
  status: DeploymentStatus;
}

export default function StatusText({ innerText, status }: Props) {
  const [labelText, setLabelText] = useState('');

  useEffect(() => {
    switch (status) {
      default:
        setLabelText(status);
        return;
      case DeploymentStatus.PendingDBDelete:
        setLabelText('pending DB delete');
        return;
      case DeploymentStatus.PendingDelete:
        setLabelText('pending delete');
        return;
      case DeploymentStatus.AwaitingBuild:
        setLabelText('awaiting build');
        return;
      case DeploymentStatus.PendingUpdate:
        setLabelText('pending update');
        return;
      case DeploymentStatus.Synced:
        setLabelText('live');
        return;
      case DeploymentStatus.Reconciling:
        setLabelText('reconciling');
        return;
      case DeploymentStatus.TearingDown:
        setLabelText('tearing down');
        return;
      case DeploymentStatus.InfraDeleted:
        setLabelText('infra deleted');
        return;
    }
  }, [status]);

  return (
    <StyledText className={getClassByStatus(status)} component="h2">
      {innerText ?? labelText}
    </StyledText>
  );
}
