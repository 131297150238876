/* eslint-disable no-restricted-globals */

import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import {
  CardActions,
  CardContent,
  IconButton,
  Icons,
  makeStyles,
  styled,
  Tooltip,
  Typography,
} from '@onehope/design-system-v2';

import { DeploymentStatus } from '../__generated__/globalTypes';
import { GetAllDeployments_deployments } from '../queries/generatedTypes/GetAllDeployments';
import StatusCard from '../StatusCard/StatusCard';
import StatusText from './StatusText';

const useStyles = makeStyles({
  root: {
    width: 275,
  },
  clickable: {
    cursor: 'pointer',
  },
  cardActions: {
    justifyContent: 'end',
  },
  slugLink: {
    borderBottom: '1px solid #333',
    '@media (prefers-color-scheme: dark)': {
      borderColor: '#fff',
    },
    display: 'inline-block',
    margin: '10px 0',
    cursor: 'pointer',
    '&:hover, &:active, &:focus': {
      opacity: 0.8,
    },
  },
});

const ButtonsWrapper = styled('div')`
  .MuiIconButton-label {
    @media (prefers-color-scheme: dark) {
      color: #ccc;
    }
  }
`;

interface Props {
  deployment: GetAllDeployments_deployments;
}

export default function DeploymentCard({ deployment }: Props) {
  const classes = useStyles();
  const history = useHistory();

  const viewDeploymentDetails = useCallback(() => {
    history.push(`/deployment/${deployment.slug}`);
  }, [deployment.slug, history]);

  const editDeployment = useCallback(() => {
    history.push(`/deployment/${deployment.slug}/edit`);
  }, [deployment.slug, history]);

  const openDeployment = useCallback(() => {
    window.open(
      `https://${deployment.slug}.amphora.onehopewine-staging.com`,
      '_blank',
    );
  }, [deployment.slug]);

  const isEditable = useMemo(() => {
    return (
      !deployment.locked && deployment.status !== DeploymentStatus.PendingDelete
    );
  }, [deployment.status, deployment.locked]);

  let expirationDaysRemaining: string | null = null;
  if (deployment.expiresAt) {
    const daysRemaining = Math.floor(
      DateTime.fromISO(deployment.expiresAt).diffNow('days').days,
    );

    if (daysRemaining < 1) {
      expirationDaysRemaining = 'less than a day!';
    } else {
      expirationDaysRemaining = `${daysRemaining} day(s)`;
    }
  }

  return (
    <StatusCard className={classes.root} statusText={deployment.status}>
      <>
        <CardContent
          className={classes.clickable}
          onClick={viewDeploymentDetails}
        >
          <StatusText status={deployment.status} />
          <h3 className={classes.slugLink}>{deployment.slug}</h3>
          {deployment.description && (
            <Typography variant="body1" component="p">
              {deployment.description}
            </Typography>
          )}
        </CardContent>

        <CardActions className={classes.cardActions} disableSpacing>
          <ButtonsWrapper>
            {deployment.status !== DeploymentStatus.Deleted && (
              <>
                {deployment.expiresAt && expirationDaysRemaining !== null && (
                  <Tooltip
                    title={`Auto-deletes in ${expirationDaysRemaining}`}
                    arial-label="Auto-delete label"
                  >
                    <IconButton
                      aria-label="View Deployment"
                      onClick={viewDeploymentDetails}
                    >
                      <Icons.AVTimer />
                    </IconButton>
                  </Tooltip>
                )}

                <Tooltip
                  title="Open this Deployment in your browser"
                  arial-label="Open this Deployment"
                >
                  <IconButton
                    aria-label="Open Deployment"
                    onClick={openDeployment}
                  >
                    <Icons.LaunchIcon />
                  </IconButton>
                </Tooltip>

                {!isEditable && (
                  <Tooltip title="This Deployment is read-only">
                    <span>
                      <IconButton
                        disabled={true}
                        aria-label="Editing is disabled"
                      >
                        <Icons.EditIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
                {isEditable && (
                  <Tooltip title="Edit Deployment configuration">
                    <IconButton aria-label="Edit" onClick={editDeployment}>
                      <Icons.EditIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
          </ButtonsWrapper>
        </CardActions>
      </>
    </StatusCard>
  );
}
