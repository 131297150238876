import { Card, muiColors, styled } from '@onehope/design-system-v2';

import { DeploymentStatus } from '../__generated__/globalTypes';

const getClassByStatus = (statusText: string) => {
  switch (statusText) {
    default:
      return '';
    case DeploymentStatus.Deleted:
    case DeploymentStatus.PendingDBDelete:
    case DeploymentStatus.PendingDelete:
    case DeploymentStatus.Reconciling:
    case DeploymentStatus.TearingDown:
      return 'deletedBg';

    case DeploymentStatus.Error:
      return 'errorBg';

    case DeploymentStatus.AwaitingBuild:
    case DeploymentStatus.PendingUpdate:
    case DeploymentStatus.InfraDeleted:
      return 'pendingBg';

    case DeploymentStatus.Synced:
      return 'syncedBg';
  }
};

const StyledCard = styled(Card)`
  @media (prefers-color-scheme: dark) {
    color: #fff;

    a:link,
    a:visited {
      border-color: #fff;
    }
  }

  &.errorBg {
    background-color: ${muiColors.red[100]};
    @media (prefers-color-scheme: dark) {
      background-color: #6f1111;
    }
  }
  &.pendingBg {
    background-color: ${muiColors.deepPurple[100]};
    @media (prefers-color-scheme: dark) {
      background-color: ${muiColors.deepPurple['800']};
    }
  }
  &.syncedBg {
    background-color: ${muiColors.green[100]};
    @media (prefers-color-scheme: dark) {
      background-color: ${muiColors.teal[900]};
    }
  }
  &.deletedBg {
    background-color: ${muiColors.grey[100]};
    opacity: 0.8;
    @media (prefers-color-scheme: dark) {
      background-color: ${muiColors.grey[700]};
    }
  }
`;

interface Props {
  children: React.ReactChild;
  className?: string;
  statusText: DeploymentStatus;
}

export default function StatusCard({ children, className, statusText }: Props) {
  return (
    <StyledCard className={`${className} ${getClassByStatus(statusText)}`}>
      {children}
    </StyledCard>
  );
}
