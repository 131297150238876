import { Icons, Link } from '@onehope/design-system-v2';

import getCircleRepoName from '../helpers/getCircleRepoName';
import { GetDeploymentDetails_deploymentEvents } from '../queries/generatedTypes/GetDeploymentDetails';
import useStyles from './sharedStyles';

type Props = {
  event: GetDeploymentDetails_deploymentEvents;
};

export default function EventDetails({ event }: Props) {
  const classes = useStyles();

  const { eventData } = event;

  if (eventData.oldTag && eventData.newTag) {
    return (
      <p>
        Version update: <code>{eventData.oldTag}</code> ➡️{' '}
        <code>{eventData.newTag}</code>
        <br />
        Commit ID: <code>{eventData.commitId}</code>
      </p>
    );
  }

  if (eventData.buildNumber !== null) {
    return (
      <p>
        CircleCI build triggered:{' '}
        <Link
          href={[
            'https://app.circleci.com/pipelines/github/ONEHOPEWINE',
            getCircleRepoName(event.repo),
            eventData.buildNumber,
          ].join('/')}
          target="_blank"
          rel="noreferrer"
        >
          #{eventData.buildNumber}
          <Icons.LaunchIcon
            style={{
              fontSize: '1em',
              position: 'relative',
              top: '0.1em',
              paddingLeft: '0.2em',
            }}
          />
        </Link>
      </p>
    );
  }

  if (eventData.commitId) {
    return (
      <p>
        Commit{' '}
        <Link
          href={`https://github.com/ONEHOPEWINE/onehope-infrastructure/commit/${eventData.commitId}`}
          target="_blank"
          rel="noreferrer"
          className={classes.commitHash}
        >
          {eventData.commitId.substr(0, 7)}
          <Icons.LaunchIcon
            style={{
              fontSize: '1em',
              position: 'relative',
              top: '0.1em',
              paddingLeft: '0.2em',
            }}
          />
        </Link>
      </p>
    );
  }

  return null;
}
