import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';

import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@onehope/design-system-v2';

import { DeploymentStatus } from '../__generated__/globalTypes';
import errorHandling from '../helpers/errorHandling';
import DEPLOYMENT_DETAILS_QUERY from '../queries/deploymentDetailsQuery';
import {
  GetDeploymentDetails,
  GetDeploymentDetailsVariables,
} from '../queries/generatedTypes/GetDeploymentDetails';
import {
  RecycleDatabase,
  RecycleDatabaseVariables,
} from '../queries/generatedTypes/RecycleDatabase';
import { RECYCLE_DATABASE_MUTATION } from '../queries/recycleDatabaseMutation';
import Details from './Details';
import EventLog from './EventLog';
import Overview from './Overview';
import useStyles from './sharedStyles';

interface SlugParams {
  slug: string;
}

export default function DeploymentDetail() {
  const [recycleConfirmOpen, setRecycleConfirmOpen] = useState(false);

  const history = useHistory();
  const classes = useStyles();
  const { slug } = useParams<SlugParams>();
  const { loading, error, data, refetch } = useQuery<
    GetDeploymentDetails,
    GetDeploymentDetailsVariables
  >(DEPLOYMENT_DETAILS_QUERY, {
    variables: {
      slug,
    },
    pollInterval: 10000,
  });

  const [recycleDatabase] = useMutation<
    RecycleDatabase,
    RecycleDatabaseVariables
  >(RECYCLE_DATABASE_MUTATION);

  const handleEdit = useCallback(
    (slug: string) => {
      history.push(`/deployment/${slug}/edit`);
    },
    [history],
  );

  const handleShowRecycle = () => {
    setRecycleConfirmOpen(true);
  };

  const handleCancelRecycle = () => {
    setRecycleConfirmOpen(false);
  };

  const handleRecycleDatabase = async () => {
    if (!data?.deployment) {
      return;
    }
    await recycleDatabase({
      variables: {
        slug: data.deployment.slug,
      },
    });

    setRecycleConfirmOpen(false);
    refetch();
  };

  const openDeployment = useCallback(() => {
    if (!data?.deployment) {
      return;
    }
    window.open(
      `https://${data.deployment.slug}.amphora.onehopewine-staging.com`,
      '_blank',
    );
  }, [data?.deployment]);

  useEffect(() => {
    refetch();
  }, []); // Runs only once because of empty dependency array

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return errorHandling(error);
  }

  if (!data?.deployment) {
    console.warn('Missing data in GraphQL response');
    return null;
  }

  const { deployment, deploymentEvents } = data;

  return (
    <>
      <Helmet>
        <title>Amphora: {slug}</title>
      </Helmet>
      <Dialog open={recycleConfirmOpen} onClose={handleCancelRecycle}>
        <DialogTitle>Recycle Database</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to recycle the database for this deployment?
            All data in its database will be instantly and permanently deleted.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelRecycle} type="secondary">
            Cancel
          </Button>
          <Button onClick={handleRecycleDatabase} color="primary">
            Recycle
          </Button>
        </DialogActions>
      </Dialog>

      <h1 className={classes.headerLink} onClick={openDeployment}>
        {slug}
        <span className={classes.muted}>.amphora.onehopewine-staging.com</span>
      </h1>

      {!deployment.locked &&
        deployment.status !== DeploymentStatus.PendingDelete && (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleEdit(deployment.slug)}
              style={{ marginRight: '1rem' }}
            >
              Edit
            </Button>
            {/* temporarily disabled until we fix database privileges <Button
              variant="contained"
              type="secondary"
              onClick={handleShowRecycle}
              style={{ marginRight: '1rem' }}
            >
              Recycle Database
            </Button> */}
          </>
        )}

      <p className={classes.heroText}>
        {deployment.description ??
          "This Deployment doesn't have a description."}
      </p>

      <Overview deployment={deployment} refetch={refetch} />
      <br />
      <Details data={data} />
      <br />
      <EventLog events={deploymentEvents} />
      <br />
      <br />
    </>
  );
}
