import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const REACT_APP_GRAPHQL_SERVER = process.env.REACT_APP_GRAPHQL_SERVER;

export default function getClient(sessionKey: string | null) {
  const httpLink = createHttpLink({
    uri: `${REACT_APP_GRAPHQL_SERVER}/graphql`,
  });

  const authLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        'x-amphora-session-key': sessionKey,
      },
    };
  });

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  });
}
