/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * The lifecycle status of a deployment.
 */
export enum DeploymentStatus {
  AwaitingBuild = 'AwaitingBuild',
  Deleted = 'Deleted',
  Error = 'Error',
  InfraDeleted = 'InfraDeleted',
  PendingDBDelete = 'PendingDBDelete',
  PendingDelete = 'PendingDelete',
  PendingUpdate = 'PendingUpdate',
  Reconciling = 'Reconciling',
  Synced = 'Synced',
  TearingDown = 'TearingDown',
}

/**
 * The type of event logged. Indicates the shape of eventData.
 */
export enum EventType {
  BuildTriggered = 'BuildTriggered',
  DatabaseRecycled = 'DatabaseRecycled',
  DeploymentCreated = 'DeploymentCreated',
  DeploymentDestroyed = 'DeploymentDestroyed',
  InfraUpdated = 'InfraUpdated',
  TagUpdated = 'TagUpdated',
}

/**
 * The status of a Deployment's service
 */
export enum HealthStatus {
  BuildMissing = 'BuildMissing',
  Healthy = 'Healthy',
  Unhealthy = 'Unhealthy',
  Unknown = 'Unknown',
}

/**
 * The name of a repository
 */
export enum Repo {
  Hopecommerce = 'Hopecommerce',
  Infrastructure = 'Infrastructure',
  Microservice = 'Microservice',
  Monorepo = 'Monorepo',
}

export interface UpsertDeploymentInput {
  slug: string;
  description?: string | null;
  ttlDays?: string | null;
  branchMicroservice?: string | null;
  branchHopecommerce?: string | null;
  branchMonorepo?: string | null;
  branchInfrastructure?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
