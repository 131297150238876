import { makeStyles, muiColors } from '@onehope/design-system-v2';

const useStyles = makeStyles({
  cardRoot: {
    width: 275,
  },
  table: {
    minWidth: 300,
  },
  muted: {
    color: muiColors.grey[600],
  },
  danger: {
    color: muiColors.red[600],
  },
  code: {
    fontFamily: 'monospace',
    fontSize: '14px',
  },
  commitHash: {
    fontFamily: 'monospace',
    fontSize: '15px',
  },
  heroText: {
    fontSize: '24px',
    lineHeight: '1.2em',
    width: '80%',
    margin: '30px 0',
  },
  headerLink: {
    cursor: 'pointer',
  },
});

export default useStyles;
