import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { CircularProgress } from '@onehope/design-system-v2';

import useStyles from '../DeploymentDetail/sharedStyles';
import errorHandling from '../helpers/errorHandling';
import DEPLOYMENT_DETAILS_QUERY from '../queries/deploymentDetailsQuery';
import {
  GetDeploymentDetails,
  GetDeploymentDetailsVariables,
} from '../queries/generatedTypes/GetDeploymentDetails';
import DeploymentForm from './DeploymentForm';

interface SlugParams {
  slug: string;
}

export default function EditDeployment() {
  const classes = useStyles();
  const { slug } = useParams<SlugParams>();
  const { loading, error, data } = useQuery<
    GetDeploymentDetails,
    GetDeploymentDetailsVariables
  >(DEPLOYMENT_DETAILS_QUERY, {
    variables: {
      slug,
    },
    pollInterval: 10000,
  });

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return errorHandling(error);
  }

  if (!data?.deployment) {
    console.warn('Missing data in GraphQL response');
    return null;
  }

  const { deployment } = data;

  return (
    <>
      <h1>
        {slug}
        <span className={classes.muted}>.amphora.onehopewine-staging.com</span>
      </h1>

      <DeploymentForm deployment={deployment} />
    </>
  );
}
