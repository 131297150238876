export function isCe(user: any): boolean {
  return user?.conciergeUser
    ? !!user?.conciergeUser?.permissions?.oneCommerce?.ceo?.access
    : !!user?.permissions?.oneCommerce?.ceo?.access;
}

export function isConciergeShopping(user: any): boolean {
  return !!user?.conciergeUser;
}

export function isCeViewing(user: any): boolean {
  const isUserCe = isCe(user);
  const isConciergeMode = isConciergeShopping(user);
  return isUserCe || isConciergeMode;
}

