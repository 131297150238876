import Cookies from 'js-cookie';

import { ApolloError } from '@apollo/client';

export default function errorHandling(error: ApolloError): JSX.Element {
  if (error.message === 'amphora session invalid') {
    console.error('amphora sessions invalid, resetting cookie');
    Cookies.remove('amphora_login');

    // eslint-disable-next-line no-restricted-globals
    location.reload();
  }
  return <p>{error.message}</p>;
}
