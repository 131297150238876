import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@onehope/design-system-v2';

import { DeploymentStatus } from '../__generated__/globalTypes';
import StatusText from '../DeploymentCardView/StatusText';
import errorExplanation from '../helpers/errorExplanation';
import { GetDeploymentDetails_deployment } from '../queries/generatedTypes/GetDeploymentDetails';
import {
  UpsertDeployment,
  UpsertDeploymentVariables,
} from '../queries/generatedTypes/UpsertDeployment';
import { UPSERT_DEPLOYMENT_MUTATION } from '../queries/upsertDeploymentMutation';
import StatusCard from '../StatusCard/StatusCard';
import useStyles from './sharedStyles';

interface Props {
  deployment: GetDeploymentDetails_deployment;
  refetch: CallableFunction;
}

export default function Overview({ deployment, refetch }: Props) {
  const classes = useStyles();
  const [statusExplanation, setStatusExplanation] = useState('');

  const [updateDeployment] = useMutation<
    UpsertDeployment,
    UpsertDeploymentVariables
  >(UPSERT_DEPLOYMENT_MUTATION);

  useEffect(() => {
    setStatusExplanation(errorExplanation(deployment.status));
  }, [deployment.status]);

  const snooze = useCallback(() => {
    updateDeployment({
      variables: {
        input: {
          slug: deployment.slug,
          ttlDays: '30',
        },
      },
    })
      .then(() => {
        console.log('refetch complete');
        refetch();
      })
      .catch((e) => {
        console.error(e);
      });
  }, [deployment, refetch, updateDeployment]);

  let expirationDaysRemaining: number | null = null;
  if (deployment.expiresAt) {
    expirationDaysRemaining = Math.floor(
      DateTime.fromISO(deployment.expiresAt).diffNow('days').days,
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item>
        <StatusCard className={classes.cardRoot} statusText={deployment.status}>
          <>
            <CardContent>
              <StatusText status={deployment.status} />
              <Typography variant="body1" component="p">
                {statusExplanation}
              </Typography>
            </CardContent>
          </>
        </StatusCard>
      </Grid>

      {deployment.errorText && (
        <Grid item>
          <StatusCard
            className={classes.cardRoot}
            statusText={DeploymentStatus.Error}
          >
            <>
              <CardContent>
                <StatusText
                  innerText="Error Details"
                  status={DeploymentStatus.Error}
                />
                <Typography
                  variant="body1"
                  component="p"
                  className={classes.code}
                >
                  {deployment.errorText}
                </Typography>
              </CardContent>
            </>
          </StatusCard>
        </Grid>
      )}

      <Grid item>
        <StatusCard
          statusText={DeploymentStatus.Deleted}
          className={classes.cardRoot}
        >
          <CardContent>
            <Typography variant="button" component="h2">
              Last Reconciled
            </Typography>
            <Typography variant="body1" component="p">
              {deployment.reconciledAt
                ? DateTime.fromISO(deployment.reconciledAt).toRelative()
                : 'Deployment has not yet been reconciled.'}
            </Typography>

            <br />

            {deployment.expiresAt && expirationDaysRemaining !== null && (
              <div
                className={expirationDaysRemaining < 1 ? classes.danger : ''}
              >
                <Typography variant="button" component="h2">
                  Auto-Delete In
                </Typography>

                {expirationDaysRemaining >= 1 && (
                  <Typography variant="body1" component="p">
                    {expirationDaysRemaining} day(s)
                  </Typography>
                )}
                {expirationDaysRemaining < 1 && (
                  <Typography variant="body1" component="p">
                    Less than a day!
                  </Typography>
                )}

                <br />

                <Button variant="contained" color="primary" onClick={snooze}>
                  Snooze (30 days)
                </Button>
              </div>
            )}
          </CardContent>
        </StatusCard>
      </Grid>
    </Grid>
  );
}
