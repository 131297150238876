import {
  muiColors,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@onehope/design-system-v2';

import { Repo } from '../__generated__/globalTypes';
import BranchLabel from '../BranchLabel';
import getCircleRepoName from '../helpers/getCircleRepoName';
import { GetDeploymentDetails } from '../queries/generatedTypes/GetDeploymentDetails';
import HealthStatusBadge from './HealthStatusBadge';
import useStyles from './sharedStyles';

const TableWrapper = styled('div')`
  tr:last-child td {
    border-bottom: 0;
  }

  @media (prefers-color-scheme: dark) {
    th {
      background-color: ${muiColors.grey[700]};
      color: #fff;
      border-color: ${muiColors.grey[700]};
    }

    td {
      background-color: ${muiColors.grey[900]};
      color: #fff;
      border-color: ${muiColors.grey[700]};
    }
  }
`;

interface Props {
  data: GetDeploymentDetails;
}

export default function Details({
  data: { deployment, deploymentBuildInfo },
}: Props) {
  const classes = useStyles();

  return (
    <>
      <h2>Branch Configuration</h2>
      <TableWrapper>
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            aria-label="Table view of the branches this Deployment uses"
          >
            <TableHead>
              <TableRow>
                <TableCell>Repo</TableCell>
                <TableCell>Deployed Branch</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[
                [Repo.Microservice, deployment.branchMicroservice],
                [Repo.Hopecommerce, deployment.branchHopecommerce],
                [Repo.Monorepo, deployment.branchMonorepo],
                [Repo.Infrastructure, deployment.branchInfrastructure],
              ].map(([repoName, branchName = '']) => (
                <TableRow key={repoName}>
                  <TableCell>{getCircleRepoName(repoName)}</TableCell>
                  <TableCell>
                    <BranchLabel branchName={branchName} />
                  </TableCell>
                  <TableCell>
                    <HealthStatusBadge
                      healthReport={
                        deploymentBuildInfo.find(
                          ({ repo }) => repo === repoName,
                        )?.healthReport
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TableWrapper>
    </>
  );
}
