import { DateTime } from 'luxon';

import { Icons, muiColors, Tooltip } from '@onehope/design-system-v2';

import { HealthStatus } from '../__generated__/globalTypes';
import { GetDeploymentDetails_deploymentBuildInfo_healthReport } from '../queries/generatedTypes/GetDeploymentDetails';

interface Props {
  healthReport?: GetDeploymentDetails_deploymentBuildInfo_healthReport;
}

export default function HealthStatusBadge({ healthReport }: Props) {
  if (!healthReport) {
    return null;
  }

  const relativeTime = DateTime.fromISO(healthReport.updateDate).toRelative();

  switch (healthReport.status) {
    case HealthStatus.Healthy:
      return (
        <Tooltip title={`Healthy (${relativeTime})`}>
          <span>
            <Icons.CheckCircleIcon color="success" />
          </span>
        </Tooltip>
      );
    case HealthStatus.Unhealthy:
      return (
        <Tooltip title={`Unhealthy (${relativeTime})`}>
          <span>
            <Icons.ErrorOutlineIcon color="warning" />
          </span>
        </Tooltip>
      );
    case HealthStatus.Unknown:
      return (
        <Tooltip title={`Unknown (${relativeTime})`}>
          <span>
            <Icons.HelpOutlineIcon color="disabled" />
          </span>
        </Tooltip>
      );
  }

  return null;
}
