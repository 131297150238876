import queryString, { ParsedQuery } from 'query-string';
import { isCe, isCeViewing } from './helpers';

/**
 * Wine Reps often copy url links and send to customers, so we make sure that whenever a Wine Rep is viewing onehopewine logged in
 * (megalith only, excluding Event Landing pages, and not shopping an event/with another Wine Rep), they have their own dealer
 * attribution in the url.
 *
 * This function is a shared util – we use it in both the megalith app and segment package!
 */
export const checkAutoAppendDealer = ({
  user,
  isMegalith,
}: {
  user: any;
  isMegalith?: boolean;
}) => {
  const cartV2 = user?.cartV2;
  const isUserCe = isCe(user);
  const isUserCeViewing = isCeViewing(user);

  const query: ParsedQuery<string> = queryString.parse(window.location.search);

  const isEventPage = window.location.pathname.includes('/event/');
  const isCeShoppingAnotherCeEvent =
    isUserCe && cartV2?.eventId && user?.userId !== cartV2?.event?.accountId;

  return (
    isMegalith &&
    isUserCeViewing &&
    !query.dealer &&
    !isEventPage &&
    !isCeShoppingAnotherCeEvent
  );
};
