import { AddressType } from './types';

export const formatNonProfitAddress = (
  nonProfitAddress: Partial<AddressType> | null | undefined,
) => {
  if (!nonProfitAddress) return '';
  const nonProfitAddressLine = nonProfitAddress?.addressLineTwo
    ? `${nonProfitAddress?.addressLineOne}, ${nonProfitAddress?.addressLineTwo}`
    : nonProfitAddress?.addressLineOne;
  return `${nonProfitAddressLine}, ${nonProfitAddress?.city}, ${nonProfitAddress?.state}, ${nonProfitAddress?.zip}`;
};

export { FeatureFlagSet } from './types';
