import {
  muiColors,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@onehope/design-system-v2';

import BranchLabel from '../BranchLabel';
import { GetDeploymentDetails_deploymentEvents } from '../queries/generatedTypes/GetDeploymentDetails';
import EventDetails from './EventDetails';
import useStyles from './sharedStyles';

const TableWrapper = styled('div')`
  tr:last-child td {
    border-bottom: 0;
  }

  @media (prefers-color-scheme: dark) {
    th {
      background-color: ${muiColors.grey[700]};
      color: #fff;
      border-color: ${muiColors.grey[700]};
    }

    td {
      background-color: ${muiColors.grey[900]};
      color: #fff;
      border-color: ${muiColors.grey[700]};
    }
  }
`;

interface Props {
  events: GetDeploymentDetails_deploymentEvents[];
}

export default function EventLog({ events }: Props) {
  const classes = useStyles();

  if (!events || events.length === 0) {
    return <p>No events found</p>;
  }

  return (
    <>
      <h2>Event Log</h2>
      <TableWrapper>
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            aria-label="Events related to this Deployment"
          >
            <TableHead>
              <TableRow>
                <TableCell>Repo</TableCell>
                <TableCell>Event Type</TableCell>
                <TableCell>Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {events.map((event) => (
                <TableRow key={event.id}>
                  <TableCell>
                    <BranchLabel branchName={event.repo.toLowerCase()} />
                  </TableCell>
                  <TableCell>
                    <BranchLabel branchName={event.eventType} />
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" component="p">
                      <EventDetails event={event} />
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TableWrapper>
    </>
  );
}
